import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import jwtDecode from "jwt-decode"
import {
  Button,
  FormHelperText,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  useMediaQuery,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { bankIdAuth } from "api/bankIdAuth"
import { setCookie } from "utils/setCookie"
import { contentContext } from "contexts"
import ssnSchema from "utils/ssnSchema"
import AuthWrapper from "components/AuthWrapper"
import Input from "components/Input"
import { BankIDLogo } from "assets"
import { useTheme } from "@mui/material/styles"

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
  },
  loading: {
    fontSize: "1rem",
    lineHeight: "1.375rem",
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
    color: theme.palette.common.white,
    width: "420px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  error: {
    background: "rgba(255, 255, 255, 0.7)",
    color: theme.palette.error.main,
    fontWeight: 500,
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.5),
    fontSize: "0.75rem",
    lineHeight: 1,
    textAlign: "center",
  },
  inputContainer: {
    boxSizing: "border-box",
    width: "100%",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  ssnInput: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      background: theme.palette.common.white,
    },
    "& .MuiFormHelperText-root": {
      background: "rgba(255, 255, 255, 0.7)",
      color: theme.palette.error.main,
      fontWeight: 500,
      margin: theme.spacing(0.5, 0),
      paddingLeft: theme.spacing(0.5),
    },
  },
  button: {
    textTransform: "none",
    borderRadius: theme.spacing(3),
    height: "3.563rem",
    width: "100%",
    boxShadow: "none",
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  checkboxWrapper: {
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "start",
    "& .MuiTypography-body1": {
      fontSize: "0.875rem",
    },
    "& .MuiCheckbox-root": {
      borderRadius: "0",
      padding: theme.spacing(0),
      margin: theme.spacing(1.5, 1.75),
      width: "1rem",
      height: "1rem",
      "&.Mui-checked": {
        color: theme.palette.primary.main,
        background: theme.palette.common.white,
      },
    },
  },
  checkbox: {
    color: theme.palette.common.white,
  },
  bankIDLogo: {
    maxWidth: "1.875rem",
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  link: {
    fontWeight: "700",
    color: theme.palette.common.white,
    textDecoration: "underline",
  },
  qrCodeImage: {
    height: "189px",
    width: "189px",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
      height: "140px",
      width: "140px",
    },
  },
  loadingSpinner: {
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
    alignSelf: "center",
  },
  qrCodeContainer: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
  },
  qrAbortButton: {
    textTransform: "none",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  qrCodeSubtitle: {
    alignSelf: "start",
    color: theme.palette.common.white,
  },
}))

const resolver = (
  { ssn },
  {
    wrongInputFormat,
    nationalIdRequirement,
    nationalIdErrorMessage,
    ageRestrictionErrorMessage,
  }
) => {
  const { error, value } = ssnSchema({
    ssn,
    wrongInputFormat,
    nationalIdRequirement,
    nationalIdErrorMessage,
    ageRestrictionErrorMessage,
  })

  const responseValues = error ? {} : value
  const responseErrors = error
    ? error.details.reduce(
        (previous, currentError) => ({
          ...previous,
          ssn: currentError,
        }),
        {}
      )
    : {}

  return {
    values: responseValues,
    errors: responseErrors,
  }
}

const BankID = () => {
  const classes = useStyles()
  const { sameDevice } = useParams()
  const content = useContext(contentContext)
  const navigate = useNavigate()
  const theme = useTheme()
  const isWidthSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const [authState, setAuthState] = useState({
    loading: false,
    error: false,
    errorMessage: null,
  })
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: (data) => resolver(data, content),
  })
  const [rememberChecked, setRememberChecked] = useState(
    !!localStorage.getItem("personalNumber")
  )
  const [qrCode, setQrCode] = useState(null)

  const rememberPersonalNumber = (personalNumberValue, checked) => {
    setRememberChecked(checked)
    if (checked) {
      localStorage.setItem("personalNumber", personalNumberValue)
    } else {
      delete localStorage.personalNumber
    }
  }

  const handleBankIdAuthError = (errorMessage) => {
    setAuthState({
      loading: false,
      error: true,
      errorMessage,
    })
  }

  const { pathname } = window.location

  useEffect(() => {
    try {
      const getQrCode = async () => {
        const { origin } = window.location
        const { resultQrCode, result } = await bankIdAuth(
          handleBankIdAuthError,
          content.defaultLoginErrorMessage,
          origin,
          sameDevice,
          pathname
        )

        result
          .then((info) => {
            const { redirect, token } = info
            const {
              data: { id },
            } = jwtDecode(token)
            setCookie({ id, token }, redirect)
          })
          .catch(() => console.log("Authentication error"))
        setQrCode(resultQrCode)
      }
      getQrCode()
    } catch {
      console.log("Authentication error")
    }
  }, [])

  const clearBankIdAuthError = () => {
    if (authState.error) {
      setAuthState({
        ...authState,
        error: false,
        errorMessage: null,
      })
    }
  }

  function isBornFirstDecadeOf2000(ssn) {
    return ssn?.length === 10 && ssn.substring(0, 2) < 9
  }

  async function onSubmit(data) {
    const { ssn } = data
    rememberPersonalNumber(ssn, rememberChecked)
    let finalSsn = ssn

    if (ssn.length !== 12) {
      finalSsn = isBornFirstDecadeOf2000(ssn)
        ? "20".concat(ssn)
        : "19".concat(ssn)
    }

    setAuthState({
      ...authState,
      loading: true,
      error: false,
      errorMessage: null,
    })
    try {
      const { token, redirect } = await bankIdAuth(
        handleBankIdAuthError,
        content.defaultLoginErrorMessage,
        origin,
        false,
        pathname,
        finalSsn
      )
      const {
        data: { id },
      } = jwtDecode(token)
      setCookie({ id, token }, redirect)
    } catch {
      console.log("Authentication error")
    }
  }

  if (authState?.loading) {
    return (
      <AuthWrapper loading={authState.loading}>
        <Typography className={classes.loading}>
          {sameDevice
            ? content.bankIdLoadingMessageDesktop
            : content.bankIdLoadingMessageMobile}
        </Typography>
      </AuthWrapper>
    )
  }

  const renderQrCode = () => {
    if (qrCode) {
      return (
        <Box className={classes.qrCodeContainer}>
          <img className={classes.qrCodeImage} src={qrCode} alt="qr" />
          {authState.error && authState.errorMessage && (
            <Typography className={classes.error}>
              {authState.errorMessage}
            </Typography>
          )}
          <Button
            onClick={() => navigate("/")}
            className={classes.qrAbortButton}
          >
            <Typography className={classes.qrCodeSubtitle}>
              {content.cancelLogin}
            </Typography>
          </Button>
        </Box>
      )
    }
    return (
      <Box className={classes.loadingSpinner}>
        <CircularProgress className={classes.loadingSpinner} size={50} />
      </Box>
    )
  }

  return (
    <AuthWrapper>
      {renderQrCode()}
      {!isWidthSmUp && (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <Box className={classes.inputContainer}>
            <Input
              type="number"
              name="ssn"
              placeholder={content.nationalId}
              control={control}
              errors={errors}
              className={classes.ssnInput}
              clearBankIdAuthError={clearBankIdAuthError}
            />
            {!errors?.ssn && authState.error && authState.errorMessage && (
              <FormHelperText className={classes.error}>
                {authState.errorMessage}
              </FormHelperText>
            )}
          </Box>
          <Box className={classes.checkboxWrapper}>
            <FormControlLabel
              value="start"
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={rememberChecked}
                  onChange={(e) => {
                    setRememberChecked(e.target.checked)
                  }}
                />
              }
              label={content.rememberMe}
              labelPlacement="end"
            />
          </Box>
          <Button variant="contained" type="submit" className={classes.button}>
            <BankIDLogo className={classes.bankIDLogo} /> {content.login}
          </Button>
        </form>
      )}
    </AuthWrapper>
  )
}

export default BankID
