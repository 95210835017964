import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Button,
  useMediaQuery,
  Link,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useTheme } from "@mui/material/styles"
import { contentContext } from "contexts"
import AuthWrapper from "components/AuthWrapper"
import { BankIDLogo } from "assets"
import config from "config"
import { bankIdAuth, bankIdAuthListener } from "api/bankIdAuth"
import { setCookie } from "utils/setCookie"
import jwtDecode from "jwt-decode"

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    color: theme.palette.common.white,
    height: "57px",
    width: "19.75rem",
    boxShadow: "none",
    fontWeight: theme.typography.fontWeightSemiBold,
    borderRadius: "200px",
    "& svg": {
      marginRight: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  bankIDLogo: {
    maxWidth: "30px",
    width: "100%",
  },
  link: {
    fontWeight: "500",
    color: theme.palette.common.white,
    textDecoration: "underline",
    cursor: "pointer",
  },
  linkContainer: {
    textAlign: "center",
    maxWidth: "320px",
    marginTop: "0.5rem",
  },
  error: {
    background: "rgba(255, 255, 255, 0.7)",
    color: theme.palette.error.main,
    fontWeight: 500,
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.5),
    fontSize: "0.75rem",
    lineHeight: 1,
    textAlign: "center",
  },
  loadingSpinner: {
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
    alignSelf: "center",
  },
}))

const ChooseBankID = () => {
  const { button, bankIDLogo, link, linkContainer, error, loadingSpinner } =
    useStyles()
  const navigate = useNavigate()
  const content = useContext(contentContext)
  const theme = useTheme()
  const isWidthSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const { pathname, origin } = window.location
  const { isDev, isTest } = config
  const url = new URLSearchParams(window.location.search)
  const [authState, setAuthState] = useState({
    loading: url.has("orderRef"),
    error: false,
    errorMessage: null,
  })

  const handleBankIdAuthError = (errorMessage) => {
    setAuthState({
      loading: false,
      error: true,
      errorMessage,
    })
  }

  useEffect(() => {
    const checkForOrderRef = async () => {
      if (url.has("orderRef")) {
        const { token, redirect } = await bankIdAuthListener(
          url.get("orderRef"),
          handleBankIdAuthError,
          content.defaultLoginErrorMessage
        )
        const {
          data: { id },
        } = jwtDecode(token)
        setCookie({ id, token }, redirect)
      }
    }
    checkForOrderRef()
  }, [])

  const startBankId = async () => {
    try {
      setAuthState({ loading: true })
      const { token, redirect } = await bankIdAuth(
        handleBankIdAuthError,
        content.defaultLoginErrorMessage,
        origin,
        true,
        pathname
      )
      const {
        data: { id },
      } = jwtDecode(token)
      setCookie({ id, token }, redirect)
    } catch {
      console.log("Authentication error")
    }
  }

  const renderLoading = () => (
    <Box className={loadingSpinner}>
      <CircularProgress className={loadingSpinner} size={50} />
    </Box>
  )

  return (
    <AuthWrapper>
      {authState.loading ? (
        renderLoading()
      ) : (
        <>
          <Button
            onClick={() => (isWidthSmUp ? navigate("/login") : startBankId())}
            className={button}
            variant="contained"
          >
            <BankIDLogo className={bankIDLogo} />
            {content.login}
          </Button>
          {(isWidthSmUp || isTest || isDev) && (
            <Box className={linkContainer}>
              <Link
                className={link}
                variant="subtitle2"
                onClick={() =>
                  isWidthSmUp ? startBankId() : navigate("/login")
                }
              >
                {isWidthSmUp
                  ? content.fileBankIdOnThisDevice
                  : content.fileBankIdOnOtherDevice}
              </Link>
            </Box>
          )}
        </>
      )}
      {authState.error && authState.errorMessage && (
        <Typography className={error}>{authState.errorMessage}</Typography>
      )}
    </AuthWrapper>
  )
}

export default ChooseBankID
