import React, { useState, useCallback, useEffect, useContext } from "react"
import {
  Box,
  AppBar,
  Toolbar,
  Drawer,
  Button,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Backdrop,
  Container,
  IconButton,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import config from "config"
import { Logo } from "assets"
import { contentContext } from "contexts"
import BurgerMenuIcon from "./BurgerMenuIcon"

const COMPANY_NAME = config.company_name
const DRAWER_WIDTH = "80%"
const CURRENT_YEAR = new Date().getFullYear()

const useStyles = makeStyles((theme) => ({
  root: (height) => ({
    position: "relative",
    height,
    display: "flex",
    flexDirection: "column",
  }),
  wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
  },
  titleContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
      marginLeft: theme.spacing(1),
    },
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    top: "56px",
  },
  displayName: {
    color: "#000",
  },
  container: {
    display: "flex",
    flexGrow: 1,
    padding: 0,
    maxWidth: "unset",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
  },
  toolbar: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  navLink: {
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: "400",

    "&:last-of-type": {
      marginLeft: theme.spacing(3),
    },
  },
  logo: {
    cursor: "pointer",
  },
}))

const toggleDrawer = (isOpen, setOpen) => (event) => {
  if (
    event.type === "keydown" &&
    (event.key === "Tab" || event.key === "Shift")
  ) {
    return
  }

  setOpen(isOpen)
}

const ListItemLink = (props) => {
  const { icon, primary, to } = props

  return (
    <ListItem button onClick={() => window.open(to, "_blank")}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={primary} />
    </ListItem>
  )
}

const RenderDrawer = ({
  isOpen,
  setOpen,
  headerItemHowItWorks,
  headerItemCommonQuestions,
  ...rest
}) => (
  <Drawer variant="persistent" open={isOpen} {...rest}>
    <List>
      <ListItemLink to={config.how_it_works} primary={headerItemHowItWorks} />
      <ListItemLink to={config.faq} primary={headerItemCommonQuestions} />
      <Divider />
      <ListItem>
        <Typography variant="caption">
          &#169; {COMPANY_NAME} {CURRENT_YEAR}
        </Typography>
      </ListItem>
    </List>
  </Drawer>
)

const Header = ({ children }) => {
  const [isOpen, setOpen] = useState(false)
  const [height, setHeight] = useState(window.innerHeight)
  const classes = useStyles(height)
  const content = useContext(contentContext)
  const { headerItemHowItWorks, headerItemCommonQuestions } = content
  const handleHeight = useCallback((e) => {
    setHeight(e.target.innerHeight)
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleHeight)
    return () => {
      window.removeEventListener("resize", handleHeight)
    }
  }, [handleHeight])

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <AppBar
          position="static"
          className={classes.appBar}
          data-testid="header"
        >
          <Toolbar className={classes.toolbar}>
            <Hidden smUp>
              <IconButton onClick={toggleDrawer(!isOpen, setOpen)}>
                <BurgerMenuIcon open={isOpen} />
              </IconButton>
            </Hidden>
            <Box className={classes.titleContainer}>
              <Logo
                className={classes.logo}
                onClick={() =>
                  (window.location.href = "https://dinpsykolog.se/")
                }
              />
            </Box>

            <Hidden smDown>
              <Button
                className={classes.navLink}
                onClick={() => window.open(config.how_it_works, "_self")}
                color="default"
              >
                {headerItemHowItWorks}
              </Button>
              <Button
                className={classes.navLink}
                onClick={() => window.open(config.faq, "_self")}
                color="default"
              >
                {headerItemCommonQuestions}
              </Button>
            </Hidden>
          </Toolbar>
        </AppBar>

        <RenderDrawer
          classes={{
            paper: classes.drawerPaper,
          }}
          isOpen={isOpen}
          setOpen={setOpen}
          headerItemHowItWorks={headerItemHowItWorks}
          headerItemCommonQuestions={headerItemCommonQuestions}
        />
        <Backdrop
          className={classes.backdrop}
          open={isOpen}
          onClick={toggleDrawer(false, setOpen)}
        />
      </div>
      <Container className={classes.container}>{children}</Container>
    </div>
  )
}

export default Header
