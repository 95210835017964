import React, { useContext } from "react"
import { Box, Typography, CircularProgress, useMediaQuery } from "@mui/material"
import { makeStyles } from "@mui/styles"
import useScript from "react-script-hook"
import { contentContext } from "contexts"
import CheckIcon from "@mui/icons-material/Check"
import { useTheme } from "@emotion/react"

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    overflowX: "hidden",
    minHeight: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(18, 15, 5, 15),
    [theme.breakpoints.down("xl")]: {
      padding: theme.spacing(1, 10),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(20, 20, 20, 5),
      justifyContent: "end",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: theme.spacing(3, 2),
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    maxWidth: "60%",
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      maxWidth: "75%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginTop: 0,
      justifyContent: "center",
      maxWidth: "100%",
    },
  },
  loginWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginTop: 0,
      justifyContent: "center",
      maxWidth: "100%",
    },
  },
  childWrapper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "320px",
    [theme.breakpoints.down("md")]: {
      flex: "none",
      display: "flex",
      flexDirection: "column",
      maxWidth: "420px",
    },
    [theme.breakpoints.down("sm")]: {
      flex: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
    marginTop: "40px",
  },
  text: {
    display: "block",
    textAlign: "left",
    color: theme.palette.common.white,
  },
  title: {
    fontSize: "50px",
    lineHeight: "61px",
    textAlign: "left",
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: theme.spacing(5),
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      marginBottom: theme.spacing(2),
      fontSize: "2.188rem",
      lineHeight: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
      lineHeight: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
    },
  },
  body: {
    fontSize: "25px",
    lineHeight: "36px",
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
    },
  },
  spinner: {
    marginTop: theme.spacing(7),
    color: theme.palette.common.white,
    alignSelf: "flex-start",
    marginLeft: theme.spacing(22.5),
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
      marginLeft: theme.spacing(0),
    },
  },
  bodyRow: {
    display: "flex",
    color: theme.palette.common.white,
    marginBottom: "5px",
    alignItems: "center",
    "& svg": {
      marginRight: "10px",
    },
  },
  bodyWrapper: {
    display: "block",
    width: "100%",
  },
}))

const AuthWrapper = ({ children, loading }) => {
  const {
    root,
    wrapper,
    text,
    title,
    body,
    spinner,
    childWrapper,
    bodyRow,
    bodyWrapper,
    loginWrapper,
  } = useStyles()
  const content = useContext(contentContext)
  const theme = useTheme()
  const isWidthSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const { pathname } = window.location

  const renderTitle = () => {
    if (pathname.includes("/login")) {
      return (
        <Typography component="span" className={`${text} ${title}`}>
          {content.login}
        </Typography>
      )
    }
    return (
      <Typography component="span" className={`${text} ${title}`}>
        {isWidthSmUp ? content.serviceTitleDesktop : content.serviceTitleMobile}
      </Typography>
    )
  }

  const renderLoginInfo = () => {
    if (pathname.includes("/login")) {
      return (
        <Box className={bodyWrapper}>
          <Box className={bodyRow}>
            <Typography component="span" className={`${text} ${body}`}>
              1) {content.loginInfo1}
            </Typography>
          </Box>
          <Box className={bodyRow}>
            <Typography component="span" className={`${text} ${body}`}>
              2) {content.loginInfo2}
            </Typography>
          </Box>
          <Box className={bodyRow}>
            <Typography component="span" className={`${text} ${body}`}>
              3) {content.loginInfo3}
            </Typography>
          </Box>
        </Box>
      )
    }
    return (
      <Box className={bodyWrapper}>
        <Box className={bodyRow}>
          <CheckIcon />
          <Typography component="span" className={`${text} ${body}`}>
            {content.serviceInfo1}
          </Typography>
        </Box>
        <Box className={bodyRow}>
          <CheckIcon />
          <Typography component="span" className={`${text} ${body}`}>
            {content.serviceInfo2}
          </Typography>
        </Box>
        <Box className={bodyRow}>
          <CheckIcon />
          <Typography component="span" className={`${text} ${body}`}>
            {content.serviceInfo3}
          </Typography>
        </Box>
      </Box>
    )
  }

  const mobileLandscape =
    window.innerWidth < 900 && window.innerWidth > 1.6 * window.innerHeight

  const statuspageId =
    process.env.NODE_ENV === "production" ? "dinpsykolog" : "dptest"
  useScript({
    src: `https://${statuspageId}.statuspage.io/embed/script.js`,
    checkForExisting: true,
  })

  return (
    <Box className={root}>
      <Box className={pathname.includes("/login") ? loginWrapper : wrapper}>
        {mobileLandscape && <Box m={4} />}
        {renderTitle()}
        {renderLoginInfo()}
        {loading && (
          <CircularProgress className={spinner} size={50} color="primary" />
        )}
      </Box>
      <Box className={childWrapper}>{children}</Box>
    </Box>
  )
}

export default AuthWrapper
