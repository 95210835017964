import differenceInYears from "date-fns/differenceInYears"

export const getAgeFromShortSsn = (ssn) => {
  const today = new Date()
  const shortBirthYear = +ssn.substring(0, 2)
  const birthMonth = +ssn.substring(2, 4)
  const birthDay = +ssn.substring(4, 6)
  const currentYear = today.getFullYear()
  const birthYear =
    currentYear - 1900 - shortBirthYear > 111
      ? 2000 + shortBirthYear
      : 1900 + shortBirthYear
  const userBirthDate = new Date(birthYear, birthMonth - 1, birthDay)
  const age = differenceInYears(today, userBirthDate)

  return age
}
