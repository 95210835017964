import axios from "axios"
import config from "config"

const BASE_URL = config.apiUrl

export const getContent = () =>
  axios
    .get(`${BASE_URL}content/auth`, {
      headers: {
        "content-type": "application/json",
      },
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error
    })
