import Joi from "@hapi/joi"
import { getAgeFromShortSsn } from "./getAgeFromShortSsn"

const method = (data, helpers) => {
  const {
    ssn,
    wrongInputFormat,
    nationalIdRequirement,
    nationalIdErrorMessage,
    ageRestrictionErrorMessage,
  } = data

  if (typeof ssn !== "string") {
    return helpers.message(wrongInputFormat)
  }

  if (ssn.length !== 10 && ssn.length !== 12) {
    return helpers.message(nationalIdRequirement)
  }

  // Luhn-algoritmen 10 digits ssn
  const shortSSN = ssn.length === 12 ? ssn.substring(2) : ssn
  const calc = shortSSN.split("").map((string, index) => {
    const number = parseInt(string)
    if (index % 2) {
      return number * 1
    }
    return number * 2 >= 10 ? number * 2 - 9 : number * 2
  })

  const reducer = (accumulator, currentValue) => accumulator + currentValue
  const sum = calc.reduce(reducer)

  if (sum % 10) {
    return helpers.message(nationalIdErrorMessage)
  }

  const age = getAgeFromShortSsn(shortSSN)
  if (age < 18) {
    return helpers.message(ageRestrictionErrorMessage)
  }
  data.this_device = data.this_device !== "false"
  return data
}

const ssnSchema = (data) => {
  const schema = Joi.custom(method, "validate ssn")

  return schema.validate(data, {
    abortEarly: false,
  })
}

export default ssnSchema
